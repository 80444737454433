<template>
  <div class="progress">
    <div
      class="progress-bar"
      role="progressbar"
      :style="`width: ${currentProgress}%`"
      aria-valuenow="currentProgress"
      aria-valuemin="0"
      aria-valuemax="100"
    />
  </div>
</template>

<script>
export default {
  name: 'CtFakeLoadingBar',
  props: {
    secondsEstimate: {
      type: Number,
      default: 4,
    },
    actuallyComplete: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentProgress: 0,
      progression: null,
    }
  },
  watch: {
    actuallyComplete(newVal) {
      if (newVal) this.wrapItUp()
    },
  },
  mounted() {
    this.manipulateUsersPatience()
    if (this.actuallyComplete) {
      this.wrapItUp()
    }
  },
  methods: {
    manipulateUsersPatience() {
      this.currentProgress = 0
      this.progression = setInterval(() => {
        this.currentProgress++
        if (this.currentProgress >= 69) this.slowItDown()
      }, this.secondsEstimate * 10)
    },
    slowItDown() {
      clearInterval(this.progression)
      this.progression = setInterval(() => {
        this.currentProgress++
        if (this.currentProgress >= 98) clearInterval(this.progression)
      }, this.secondsEstimate * 100)
    },
    wrapItUp() {
      const finalAmount = 100 - this.currentProgress
      clearInterval(this.progression)
      this.progression = setInterval(() => {
        this.currentProgress += finalAmount / 4
        if (this.currentProgress >= 100) {
          this.$emit('progress-bar-complete')
          clearInterval(this.progression)
        }
      }, 125)
    },
  },
}
</script>
