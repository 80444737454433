var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "progress" }, [
    _c("div", {
      staticClass: "progress-bar",
      style: `width: ${_vm.currentProgress}%`,
      attrs: {
        role: "progressbar",
        "aria-valuenow": "currentProgress",
        "aria-valuemin": "0",
        "aria-valuemax": "100",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }